import * as Sentry from "@sentry/nuxt";

const { public: { sentry } } = useRuntimeConfig();

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: sentry.dsn,
  environment: sentry?.environment ?? 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: sentry?.tracesSampleRate ?? 1, // Change in prod if necessary

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', sentry?.coreApiBase ?? 'https://coreapi.io'],

  replaysSessionSampleRate: sentry?.replaysSessionSampleRate ?? 1.0, // Change in prod if necessary
  replaysOnErrorSampleRate: sentry?.replaysOnErrorSampleRate ?? 1.0, // Change in prod if necessary
});
